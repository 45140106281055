import { Fragment, useEffect, useState } from 'react'

import Std from './files/std.png'

const Home = () => {
  const [time, setTime] = useState('')
  const [municipality, setMunicipality] = useState([])
  const [villa, setVilla] = useState([])
  const [image, setImage] = useState('')

  const calculate = () => {
    var message = '0 giorni, 0 ore, 0 minuti, 0 secondi'
    var str = '30/05/2018-17:00:00'

    /*send data as dd/MM/yyyy-HH:mm:ss*/
    var myDate = new Date()
    var year = myDate.getFullYear()
    var month = myDate.getMonth()

    if (month <= 9) month = '0' + (month + 1)
    var day = myDate.getDate()
    if (day <= 9) day = '0' + day
    var hour = myDate.getHours()
    if (hour <= 9) hour = '0' + hour
    var minutes = myDate.getMinutes()
    if (minutes <= 9) minutes = '0' + minutes
    var seconds = myDate.getSeconds()
    if (seconds <= 9) seconds = '0' + seconds

    //Parsing della data/ora in ingresso [dd/MM/yyyy-HH:mm:ss]

    let n = str.slice(0, 19)
    n = n.split('-')
    var daysplit = n[0].split('/')
    var datesplit = n[1].split(':')

    var day1 = daysplit[0]
    var month1 = daysplit[1]
    var year1 = daysplit[2]

    var hour1 = datesplit[0]
    var minutes1 = datesplit[1]
    var seconds1 = datesplit[2]

    //calcola la differenza

    var date1 = new Date(year1, month1, day1, hour1, minutes1, seconds1) // ora in ingresso
    var date2 = new Date(year, month, day, hour, minutes, seconds) // ora attuale

    //data futura
    // var diff = date1 - date2;
    //data precedente
    var diff = date2 - date1

    if (diff > 0) {
      var msec = diff
      const yy = Math.floor(msec / 1000 / 60 / 60 / 24 / 365)
      msec -= yy * 1000 * 60 * 60 * 24 * 365
      var gg = Math.floor(msec / 1000 / 60 / 60 / 24)
      msec -= gg * 1000 * 60 * 60 * 24
      var hh = Math.floor(msec / 1000 / 60 / 60)
      msec -= hh * 1000 * 60 * 60
      var mm = Math.floor(msec / 1000 / 60)
      msec -= mm * 1000 * 60
      var ss = Math.floor(msec / 1000)
      msec -= ss * 1000

      var out = ''
      if (yy === 1) out += yy + ' anno'
      else out += yy + ' anni'
      out += ', '
      if (gg === 1) out += gg + ' giorno'
      else out += gg + ' giorni'
      out += ', '
      if (hh === 1) out += hh + ' ora'
      else out += hh + ' ore'
      out += ', '
      if (mm === 1) out += mm + ' minuto'
      else out += mm + ' minuti'
      out += ', '
      if (ss === 1) out += ss + ' secondo'
      else out += ss + ' secondi'

      setTime(out)
    } else {
      setTime(message)
    }
  }

  useEffect(() => {
    const i = setInterval(() => {
      calculate()
    }, 1000)

    return () => clearInterval(i)
  }, [])

  const importAll = (r) => {
    return r.keys().map(r)
  }

  useEffect(() => {
    const municipalityImages = importAll(
      require.context('./files/comune', false, /\.(png|jpe?g|svg)$/)
    )
    const villaImages = importAll(
      require.context('./files/villa', false, /\.(png|jpe?g|svg)$/)
    )
    setVilla(villaImages)
    setMunicipality(municipalityImages)
  }, [])

  const showImageHandler = (image) => {
    setImage(image)
  }

  return (
    <Fragment>
      <div className='show'>
        <img src={Std} alt='save the date' />
        <br />
        Viviana & Mauro
      </div>
      <div className='counter'>
        <h1>Siamo sposati da</h1>
        <div className='sep'></div>
        <span id='time'>{time}</span>
      </div>
      <div className='program'>
        <div className='sep'></div>
        <h1>In comune</h1>
        <div className='list-images'>
          {municipality.map((image, key) => (
            <div key={key} onClick={() => showImageHandler(image)}>
              <img src={image} alt='info'></img>
            </div>
          ))}
        </div>
      </div>
      <div className='counter'>
        <div className='sep'></div>
        <h1>In villa</h1>
        <div className='list-images'>
          {villa.map((image, key) => (
            <div key={key} onClick={() => showImageHandler(image)}>
              <img src={image} alt='info'></img>
            </div>
          ))}
        </div>
      </div>
      <div className='program'>
        <h1>grazie a tutti</h1>
      </div>
      <div className='counter'>
        <h1>lista nozze</h1>
        Abbiamo tutto quello che ci serve, o quasi, ci piacerebbe finire di
        sistemare casa e magari farci una bella vacanza.
        <br />
        Se vuoi contribuire ai nostri progetti puoi farci un regalo.
        <br />
        <br />
        Intestato a:
        <br />
        Mauro Sala Viviana Biffi
        <div className='iban'>IT42J0347501605CC0011000020</div>
      </div>
      {image && (
        <div className='lightBox' onClick={() => setImage('')}>
          <img src={image} alt='info'></img>
        </div>
      )}
    </Fragment>
  )
}

export default Home
