import React, { Fragment } from 'react'
import Footer from './Footer'
import Home from './Home'

function App() {
  return (
    <Fragment>
      <Home />
      <Footer />
    </Fragment>
  )
}

export default App
