import { Fragment } from 'react'

import File1 from './files/01.jpg'
import File2 from './files/02.jpg'
import File3 from './files/03.jpg'

const Footer = () => (
  <Fragment>
    <footer>
      <div className='sep'></div>
      <span className='tag'>#VMwedding</span>
    </footer>
    <div className='images'>
      <img src={File1} alt='1' />
      <img src={File2} alt='2' />
      <img src={File3} alt='3' />
    </div>
  </Fragment>
)

export default Footer
